<template>
    <workspace-wrapper>
        <template #workspace>
            <hilo-workspace :currentCards="stateData.currentSpinResult.currentCards"
                :selectedIndex="stateData.currentSpinResult.selectedIndex"
                :selectsHistory="stateData.currentSpinResult.selectsHistory"
                :selectedCardsHistory="stateData.currentSpinResult.selectedCardsHistory" />
        </template>
    </workspace-wrapper>
</template>

<script>
import WorkspaceWrapper from "../../Common/WorkspaceWrapper";
import HiloWorkspace from "./HiloWorkspace.vue";

export default {
    props: {
        stateData: {
            type: Object,
            required: true,
            default() {
                return {
                    currentSpinResult: {
                        currentCards: null,
                        selectedIndex: null,
                        selectsHistory: [],
                        selectedCardsHistory: []
                    }
                }
            }
        }
    },
    components: {
        WorkspaceWrapper,
        HiloWorkspace
    }
}
</script>

<style scoped></style>
